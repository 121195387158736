

































import modelMixin from "@/mixins/model.ts";
import PickerSelect from "./PickerSelect.vue";
import Avatar from "@/components/avatar/Avatar.vue";
import { OrganizationWorkspaceUser } from "@/generated/graphql";
import mixins from "vue-typed-mixins";
import { ActionTypes } from "@/store/action-types";
import { debounce } from "@/utils";

export default mixins(modelMixin<OrganizationWorkspaceUser>()).extend({
  name: "UserPicker",
  components: { PickerSelect, Avatar },

  props: {
    value: {
      type: [Object, String]
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "user-picker"
    },
    label: {
      type: String,
      default: "User"
    },
    placeholder: {
      type: String,
      default: "Pick a user or add an email"
    }
  },
  created() {
    this.debouncedFilter = this.debounce<(...args: unknown[]) => unknown>(
      this.onFilterChange,
      400
    );
  },
  data() {
    return {
      isFetchingUsers: false,
      debouncedFilter: null as ((...args: unknown[]) => unknown) | null
    };
  },
  computed: {
    users(): OrganizationWorkspaceUser[] {
      return this.$storeTyped.state.workspace.potentialWorkspaceUsers;
    },
    sortedUsers(): OrganizationWorkspaceUser[] {
      return [...this.users].sort((a, b) => {
        const af = a.firstName === null ? a.email : a.firstName;
        const bf = b.firstName === null ? b.email : b.firstName;
        return (af as string).localeCompare(bf as string);
      });
    }
  },
  destroyed() {
    this.$storeTyped.dispatch(ActionTypes.RESET_POTENTIAL_WORKSPACE_USERS);
  },
  methods: {
    debounce,
    fullName(value: OrganizationWorkspaceUser) {
      if (value) {
        return value.firstName || value.lastName
          ? `${value.firstName} ${value.lastName}`
          : value.email;
        // @ts-ignore
      } else if (this.localValue && (this.localValue as unknown).email) {
        // @ts-ignore
        return (this.localValue as unknown).email;
      } else {
        return this.placeholder;
      }
    },
    setEmail(email: string) {
      // @ts-ignore
      this.localValue = { email, lastName: "", firstName: "", pictureUrl: "" };
    },
    onFilterChange(filter: unknown) {
      this.isFetchingUsers = true;
      this.$storeTyped
        .dispatch(ActionTypes.FETCH_POTENTIAL_WORKSPACE_USERS, filter as string)
        .finally(() => (this.isFetchingUsers = false));
    }
  }
});
