


















import Vue, { PropType } from "vue";
import Avatar from "@/components/avatar/Avatar.vue";
import { User } from "@/generated/graphql";
import { BaseUser } from "@/models/user";

export default Vue.extend({
  name: "UserIntro",
  components: { Avatar },
  props: {
    user: {
      type: Object as PropType<BaseUser>,
      required: true
    }
  },
  computed: {
    isInvited(): boolean {
      return Boolean(this.user.invitationDate);
    },
    invitationDate(): Date | null {
      return this.user.invitationDate
        ? new Date(this.user.invitationDate)
        : null;
    }
  },
  filters: {
    fullName(value: User): string {
      return `${value.firstName} ${value.lastName}`;
    }
  }
});
