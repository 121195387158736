














import Vue, { PropType } from "vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import { Validation } from "vuelidate";

export default Vue.extend({
  name: "FormField",
  components: { FieldGroup },
  props: {
    value: {
      type: String
    },
    v: {
      type: Object as PropType<Validation>,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    purpleLabel: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    state(): boolean | null {
      return this.v.$dirty && this.v.$error ? false : null;
    }
  },
  created() {
    this.tmp = this.value;
  },
  watch: {
    value(val) {
      this.tmp = val;
    }
  },
  data() {
    return {
      tmp: ""
    };
  },
  methods: {
    setField(value: string) {
      this.tmp = value;
      this.$emit("input", value);
      this.v.$reset();
    }
  }
});
