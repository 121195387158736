import mixins from "vue-typed-mixins";
import { validationMixin, Validation } from "vuelidate";

const formMixin = mixins(validationMixin).extend({
  methods: {
    focusFirstStatus(component: Vue): void {
      const firstEl = component.$el.querySelector(
        ".is-invalid"
      ) as HTMLElement | null;
      if (firstEl && firstEl.offsetParent) {
        window.scrollTo({
          behavior: "smooth",
          left: 0,
          top: (firstEl.offsetParent as HTMLElement).offsetTop
        });
        firstEl.focus({ preventScroll: true });
      }
    },
    validate($v: Validation): void {
      $v.$touch();
      this.$nextTick(() => this.focusFirstStatus(this));
    }
  }
});

export default formMixin;
