






































































































import { PropType } from "vue";
import mixins from "vue-typed-mixins";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import LayoutWithWsLogo from "@/components/layout/LayoutWithWsLogo.vue";
import FormField from "@/components/form/FormField.vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import FilterTable from "@/components/table/FilterTable.vue";
import UserIntro from "@/components/avatar/UserIntro.vue";
import UserPicker from "@/components/form/UserPicker.vue";

import { required, email } from "vuelidate/lib/validators";

import filterTableMixin from "@/mixins/filterTable";

import formMixin from "@/mixins/form";
import { UserFilter } from "@/models/user";
import { Option } from "../form/EnumSelect.vue";
import {
  OrganizationUser,
  Role,
  OrganizationWorkspaceUser
} from "@/generated/graphql";
import { confirmModal } from "@/modal";

const eaWorkspaces = ["workspace:contents", "workspace:members"];
const defaultFilter = {
  name: "",
  role: "",
  limit: 10
};
export default mixins(
  formMixin,
  filterTableMixin<UserFilter>(defaultFilter, ["role"])
).extend({
  name: "UserTable",
  props: {
    users: {
      type: Array as PropType<
        OrganizationWorkspaceUser[] | OrganizationUser[] | null
      >,
      default: () => []
    },
    roles: {
      type: Array as PropType<Role[]>,
      default: () => []
    },
    layoutComponent: {
      type: String,
      required: true
    },
    noPickerOnInvite: {
      type: Boolean,
      default: false
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: String,
    pretitle: String,
    activateOpenWorkspace: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LayoutWithPageHeader,
    LayoutWithWsLogo,
    UserIntro,
    FilterTable,
    UserPicker,
    FormField,
    FieldGroup
  },
  computed: {
    formattedRoles(): Option<string>[] {
      if (this.eAdvocacy == "false") {
        return this.roles.map(role => ({
          value: role.value,
          text: role.name
        }));
      } else if (this.eAdvocacy == "true") {
        return this.roles
          .filter(role => eaWorkspaces.indexOf(role.value) > -1)
          .map(role => ({
            value: role.value,
            text: role.name
          }));
      } else {
        return this.roles.map(role => ({
          value: role.value,
          text: role.name
        }));
      }
    },
    filterSelectOptions(): Option<string>[] {
      return [{ value: "", text: "All roles" }, ...this.formattedRoles];
    }
  },
  mounted() {
    if (localStorage.ea) {
      this.eAdvocacy = localStorage.ea;
    }
  },
  watch: {
    eAdvocacy(neweAdvocacy) {
      localStorage.ea = neweAdvocacy;
    }
  },
  data() {
    return {
      defaultFilter,
      fields: [
        { key: "user", label: "User" },
        { key: "role", label: "Roles" }
      ],
      inviteForm: {
        user: this.noPickerOnInvite ? { email: "" } : null,
        roles: [] as string[]
      },
      inviteModalId: "invite-user-modal",
      isEditModal: false,
      eAdvocacy: localStorage.ea
    };
  },
  methods: {
    loadMore() {
      this.$emit("filter", this.filter, true);
    },
    // custom hook from filter mixin
    onFilterChange() {
      this.$emit("filter", this.filter, false);
    },
    openModal(user: OrganizationWorkspaceUser | OrganizationUser) {
      this.$v.$reset();
      this.isEditModal = Boolean(user);
      this.inviteForm = this.isEditModal
        ? {
            user: { ...user },
            roles: user.roles ? (user.roles as Role[]).map(r => r.value) : []
          }
        : {
            user: this.noPickerOnInvite ? { email: "" } : null,
            roles: []
          };
      this.$bvModal.show(this.inviteModalId);
    },

    deleteUser(user: OrganizationWorkspaceUser | OrganizationUser) {
      const confirmFn = () => this.$emit("delete", user);
      confirmModal(
        this,
        confirmFn,
        "Please confirm you want to remove this user.",
        "User deletion"
      );
    },
    submitInviteForm(e: Event) {
      this.validate(this.$v.inviteForm);
      if (!this.$v.inviteForm.$anyError) {
        this.$emit(this.isEditModal ? "edit" : "invite", this.inviteForm);
        this.$bvModal.hide(this.inviteModalId);
      } else {
        e.preventDefault();
      }
    },
    getRoleLabel(role: string) {
      const found = this.roles.find(r => r.value === role);
      return found ? found.name : "";
    }
  },
  validations: {
    inviteForm: {
      user: {
        email: {
          required,
          email
        }
      },
      roles: {
        required
      }
    }
  }
});
